<template>
  <section class="min-h-[60vh] my-8 p-2 flex flex-col items-center justify-center">
    <h3 class="text-2xl font-display text-gray-700 sm:text-3xl">
      Vous n'avez pas de choix favoris !
    </h3>
    <p class="mt-2 text-gray-700">
      Trouver
      <router-link
        :to="{ name: 'Shop' }"
        class="text-primary border-b-2 border-red-400 hover:text-red-700"
      >
        les produits qui vous plaisent
      </router-link>
      et cliquez sur l'icône
      <span class="inline-block -mb-3 ml-1 p-2 rounded-full bg-white shadow-md">
        <HeartIcon class="w-6 h-6 text-gray-300 hover:text-primary transition-colors" />
      </span>
    </p>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { HeartIcon } from '@heroicons/vue/solid';

export default defineComponent({
  components: { HeartIcon },
});
</script>
