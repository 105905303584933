<template>
  <main class="max-w-screen-2xl mx-auto px-4 py-10 sm:py-16 lg:px-16">
    <h1 class="text-4xl font-heading md:mb-20 md:text-5xl">Mes choix favoris</h1>

    <transition name="fade" mode="out-in">
      <Wishlist v-if="wishlist && wishlist.products.length" :wishlist="wishlist" />
      <WishlistEmpty v-else />
    </transition>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Wishlist from '@/components/wishlist/Wishlist.vue';
import WishlistEmpty from '@/components/wishlist/WlishListEmpty.vue';
import useWishlist from '@/hooks/wishlist/use-wishlist';

export default defineComponent({
  components: { WishlistEmpty, Wishlist },
  setup() {
    const { wishlist } = useWishlist();

    return {
      wishlist,
    };
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
