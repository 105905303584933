
import { defineComponent, PropType } from 'vue';

import { Wishlist } from '@/types/models';
import WishlistCartd from './WishlistCard.vue';

export default defineComponent({
  components: { WishlistCartd },
  props: {
    wishlist: {
      type: Object as PropType<Wishlist>,
      required: true,
    },
  },
});
