
import { defineComponent } from 'vue';

import Wishlist from '@/components/wishlist/Wishlist.vue';
import WishlistEmpty from '@/components/wishlist/WlishListEmpty.vue';
import useWishlist from '@/hooks/wishlist/use-wishlist';

export default defineComponent({
  components: { WishlistEmpty, Wishlist },
  setup() {
    const { wishlist } = useWishlist();

    return {
      wishlist,
    };
  },
});
