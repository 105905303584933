<template>
  <section
    class="min-h-[60vh] my-8 grid gap-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"
  >
    <WishlistCartd v-for="product in wishlist.products" :key="product.id" :product="product" />
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Wishlist } from '@/types/models';
import WishlistCartd from './WishlistCard.vue';

export default defineComponent({
  components: { WishlistCartd },
  props: {
    wishlist: {
      type: Object as PropType<Wishlist>,
      required: true,
    },
  },
});
</script>
