
import { defineComponent, PropType, ref } from 'vue';
import { PlusIcon, XIcon } from '@heroicons/vue/outline';

import { Product } from '@/types/models';
import AppRoundButton from '@/components/shared/AppRoundButton.vue';
import ProductOptionsModal from '@/components/product/ProductOptionsModal.vue';
import useWishlist from '@/hooks/wishlist/use-wishlist';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: { AppRoundButton, ProductOptionsModal, PlusIcon, XIcon },
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  setup(props) {
    const productOptionsModal = ref<InstanceType<typeof ProductOptionsModal>>();
    const { useRemoveWishlistItem } = useWishlist();
    const { removeWishlistItem, loading, errors } = useRemoveWishlistItem();
    const { showToast } = useToast();

    const removeItem = async () => {
      await removeWishlistItem(props.product.id);

      if (!errors.value.length) {
        showToast({ message: `${props.product.name} supprimé` });
      } else {
        errors.value.forEach(({ message }) => showToast({ message, type: 'failure' }));
      }
    };

    return {
      productOptionsModal,
      loading,
      removeItem,
    };
  },
});
