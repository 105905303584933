<template>
  <article class="h-96 rounded-2xl shadow-md overflow-hidden">
    <div class="h-3/5 overflow-hidden">
      <router-link :to="{ name: 'Product', params: { slug: product.slug } }">
        <img
          :src="product.image?.url"
          width="340"
          height="220"
          :alt="product.name"
          class="
            w-full
            h-full
            object-cover
            transition-transform
            transform
            duration-500
            hover:scale-110
          "
        />
      </router-link>
    </div>
    <div class="h-2/5 p-4 flex flex-col">
      <router-link :to="{ name: 'Product', params: { slug: product.slug } }">
        <h3 class="text-2xl font-heading">{{ product.name }}</h3>
      </router-link>
      <div class="mt-auto flex justify-between items-center">
        <p class="text-3xl font-display text-gray-900">{{ product.formattedPrice }}</p>
        <div class="space-x-4">
          <AppRoundButton
            class="bg-primary hover:bg-white"
            label="Ajouter au panier"
            @click="productOptionsModal?.popup?.open()"
          >
            <PlusIcon class="h-6 w-6 text-white transition-colors group-hover:text-gray-800" />
          </AppRoundButton>
          <AppRoundButton
            class="bg-white"
            label="Supprimer de la liste de souhaits"
            :loading="loading"
            @click="removeItem"
            loadingIndicatorColor="text-primary"
          >
            <XIcon class="h-6 w-6 text-gray-300 transition-colors group-hover:text-primary" />
          </AppRoundButton>
        </div>
      </div>
    </div>

    <ProductOptionsModal ref="productOptionsModal" :product="product" />
  </article>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { PlusIcon, XIcon } from '@heroicons/vue/outline';

import { Product } from '@/types/models';
import AppRoundButton from '@/components/shared/AppRoundButton.vue';
import ProductOptionsModal from '@/components/product/ProductOptionsModal.vue';
import useWishlist from '@/hooks/wishlist/use-wishlist';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: { AppRoundButton, ProductOptionsModal, PlusIcon, XIcon },
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  setup(props) {
    const productOptionsModal = ref<InstanceType<typeof ProductOptionsModal>>();
    const { useRemoveWishlistItem } = useWishlist();
    const { removeWishlistItem, loading, errors } = useRemoveWishlistItem();
    const { showToast } = useToast();

    const removeItem = async () => {
      await removeWishlistItem(props.product.id);

      if (!errors.value.length) {
        showToast({ message: `${props.product.name} supprimé` });
      } else {
        errors.value.forEach(({ message }) => showToast({ message, type: 'failure' }));
      }
    };

    return {
      productOptionsModal,
      loading,
      removeItem,
    };
  },
});
</script>
